import React from "react";
import NavBar from "./NavBar";
import Footer from "./Footer";
import WhatsApp from "./whatsapp";
import { Slide } from 'react-reveal';

function PrivacyPolicy() {
  return (
    <>
      <NavBar />
      <hr />
      <Slide left>
        <div className="relative">
          <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50">
            <h1 className="text-[#e57c23] text-3xl sm:text-6xl font-bold">
              Privacy Policy
            </h1>
          </div>
        </div>
      </Slide>
      <div className="container mt-5 p-2">
        <Slide left>
          <div className="w-full px-4">
            <p className="text-xl font-bold sm:text-4xl mb-4 sm:text-start text-center">
              Our Privacy Policy
            </p>
            <p className="sm:text-base text-sm text-start font-sans mb-4 text-[#979797]">
              Last Updated: [22 October 2023]
            </p>
            <p className="sm:text-base text-sm text-start font-sans mb-4 text-[#979797]">
              Welcome to [Team X-MARK]! This Privacy Policy describes how
              we collect, use, and disclose personal information when you use our
              website. By accessing or using our website, you agree to the terms
              of this Privacy Policy. Please read it carefully before using our
              website.
            </p>
            <p className="sm:text-base text-sm text-start font-sans mb-4 text-[#979797]">
              <strong>Information We Collect:</strong> We may collect personal
              information such as your name, email address, and phone number when
              you use our website.
            </p>
            <p className="sm:text-base text-sm text-start font-sans mb-4 text-[#979797]">
              <strong>How We Use Your Information:</strong> We may use the
              information we collect to communicate with you, improve our website,
              and provide you with relevant information and services.
            </p>
            <p className="sm:text-base text-sm text-start font-sans mb-4 text-[#979797]">
              <strong>Disclosure of Your Information:</strong> We may disclose
              your personal information to third parties who assist us in
              operating our website and providing services to you.
            </p>
            <p className="sm:text-base text-sm text-start font-sans mb-4 text-[#979797]">
              <strong>Cookies:</strong> We may use cookies and similar tracking
              technologies to track activity on our website and collect certain
              information.
            </p>
            <p className="sm:text-base text-sm text-start font-sans mb-4 text-[#979797]">
              <strong>Changes to This Privacy Policy:</strong> We reserve the
              right to update or change our Privacy Policy at any time. Any
              changes will be effective immediately upon posting on our website.
              Your continued use of our website after any such changes
              constitutes acceptance of the new Privacy Policy.
            </p>
            <p className="sm:text-base text-sm text-start font-sans mb-4 text-[#979797]">
              <strong>Contact Us:</strong> If you have any questions about our
              Privacy Policy, please contact us at [Contact@teamxmark.com].
            </p>
          </div>
        </Slide>
      </div>
      <Footer />
      <WhatsApp />
    </>
  );
}

export default PrivacyPolicy;
