import React from "react";
import NavBar from "./NavBar";
import Footer from "./Footer";
import WhatsApp from "./whatsapp";
import { Slide } from 'react-reveal';

function TermsAndConditions() {
  return (
    <>
      <NavBar />
      <hr />
      <Slide left>
        <div className="relative">
          <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50">
            <h1 className="text-[#e57c23] text-3xl sm:text-6xl font-bold">
              Terms and Conditions
            </h1>
          </div>
        </div>
      </Slide>
      <div className="container mt-5 p-2">
        <Slide left>
          <div className="w-full px-4">
            <p className="sm:text-base text-sm text-start font-sans mb-4 text-[#979797]">
              Last Updated: [22 October 2023]
            </p>
            <p className="sm:text-base text-sm text-start font-sans mb-4 text-[#979797]">
              Welcome to [Team X-MARK]! These Terms and Conditions
              ("Terms") govern your use of our website and services. By accessing
              or using our website, you agree to be bound by these Terms. Please
              read them carefully before using our website.
            </p>
            <p className="sm:text-base text-sm text-start font-sans mb-4 text-[#979797]">
              <strong>Acceptance of Terms:</strong> By accessing or using our
              website, you agree to be bound by these Terms. If you disagree with
              any part of these Terms, you may not access or use our website.
            </p>
            <p className="sm:text-base text-sm text-start font-sans mb-4 text-[#979797]">
              <strong>Changes to Terms:</strong> We reserve the right to modify
              or replace these Terms at any time. Any changes will be effective
              immediately upon posting on our website. Your continued use of our
              website after any such changes constitutes acceptance of the new
              Terms.
            </p>
            <p className="sm:text-base text-sm text-start font-sans mb-4 text-[#979797]">
              <strong>Privacy Policy:</strong> Your privacy is important to us.
              Please review our Privacy Policy [link to Privacy Policy] for
              information on how we collect, use, and disclose personal
              information.
            </p>
            <p className="sm:text-base text-sm text-start font-sans mb-4 text-[#979797]">
              <strong>Intellectual Property:</strong> All content on our website,
              including text, graphics, logos, images, and software, is the
              property of [Your Company Name] or its licensors and is protected
              by copyright and other intellectual property laws. You may not use,
              reproduce, modify, or distribute any content from our website
              without our prior written consent.
            </p>
            <p className="sm:text-base text-sm text-start font-sans mb-4 text-[#979797]">
              <strong>Disclaimer:</strong> Our website is provided on an "as is"
              and "as available" basis without any warranties of any kind, either
              express or implied. We do not warrant that our website will be
              error-free or uninterrupted, or that any defects will be corrected.
            </p>
            <p className="sm:text-base text-sm text-start font-sans mb-4 text-[#979797]">
              <strong>Limitation of Liability:</strong> In no event shall [Your
              Company Name] or its affiliates be liable for any indirect,
              incidental, special, or consequential damages arising out of or in
              any way connected with your use of our website.
            </p>
            <p className="sm:text-base text-sm text-start font-sans mb-4 text-[#979797]">
              <strong>Governing Law:</strong> These Terms shall be governed by and
              construed in accordance with the laws of [Your Jurisdiction],
              without regard to its conflict of law provisions.
            </p>
            <p className="sm:text-base text-sm text-start font-sans mb-4 text-[#979797]">
              <strong>Contact Us:</strong> If you have any questions about these
              Terms, please contact us at [Contact Email].
            </p>
          </div>
        </Slide>
      </div>
      <Footer />
      <WhatsApp />
    </>
  );
}

export default TermsAndConditions;
